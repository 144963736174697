<div class="registred-footer-section">
  <!-- <a [routerLink]="['/']">
  <img src="/assets/icons/new_reg_logo.png"/>
  </a> -->
  <div class="footer-right-sec">
    <div class="d-flex">
    <label class="justify-content-right">©2021 EVCS</label>
  </div>
    <div>
      <ul>
        <li>
          <a class="helpUrl" href="javascript:void(0)"> Terms of Use</a>
        </li>
        <li>
          <a class="helpUrl" href="javascript:void(0)"> Legal Notice & Privacy Policy</a>
        </li>
        <li>
          <a class="helpUrl" href="javascript:void(0)"> Help Guides</a>
        </li>
      </ul>
    </div>
    <div class="footer-icons">
    </div>
  </div>
</div>