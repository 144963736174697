<div class="container-fluid mt-4">
    <div class="card">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-md-6 lg-6">
            <h2 class="h2">{{'allTypeContent.queryDetails' | translate }}</h2>
          </div>
          <div class="col-md-6 lg-6 text-end"></div>
        </div>
        <div class="body-content-outer">
          
            <form [formGroup]="queryForm" #promocodeGeneration="ngForm" class="margin-bottom-40 ucg-container"
                autocomplete="off">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-xs-12 left-section">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>Select Organization</mat-label>
                                    <mat-select formControlName="tenants" tabindex="1" [disabled]="isMasterAdmin || queryDetails?.reply?.length"
                                        (selectionChange)="tenantSelection($event.value)">
                                        <mat-option *ngFor="let tenant of tenants" [value]="tenant.tenantId">
                                            {{tenant.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>Select Site</mat-label>
                                    <mat-select formControlName="sites" tabindex="2" [disabled]="isMasterAdmin || queryDetails?.reply?.length"
                                        (selectionChange)="siteSelection($event.value)">
                                        <mat-option *ngFor="let site of sites" [value]="site.siteId">
                                            {{site.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>Select Charge Points</mat-label>
                                    <mat-select formControlName="chargePoints" tabindex="3" [disabled]="isMasterAdmin || queryDetails?.reply?.length"
                                        (selectionChange)="chargePointSelection($event.value)">
                                        <mat-option *ngFor="let chargePoint of chargePoints"
                                            [value]="chargePoint.chargePointId">
                                            {{chargePoint.chargePointId}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-12">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>{{'formFields.description' | translate}}</mat-label>
                                    <textarea matInput autocomplete="off" [rows]="5"
                                        placeholder="{{'formFields.description' | translate}}" tabindex="4"
                                        formControlName="description" required [readonly]="isMasterAdmin || queryDetails?.reply?.length"></textarea>
                                    <mat-error>
                                        <span
                                            *ngIf="(!queryForm.get('description')?.valid && !queryForm.get('description')?.errors?.serverError) && !description">
                                            {{'requiredFields.description' | translate }}</span>
                                        <span
                                            *ngIf="!queryForm.get('description')?.errors?.serverError && (description || (queryForm.get('description')?.valid && !description))">
                                            {{'requiredFields.description' | translate }}</span>
                                    </mat-error>
                                    <mat-error>
                                        <span *ngIf="queryForm.get('description')?.errors?.serverError | translate">
                                            {{queryForm.get('description')?.errors?.serverError |
                                            translate}}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div *ngIf="(isMasterAdmin && queryDetails?.reply?.length) || queryDetails?.reply?.length" class="form-group col-md-12">
                                <h4>Replies</h4>
                                <div *ngFor="let item of queryDetails.reply">
                                    <mat-card>
                                        <mat-card-content>
                                            <b>{{item.createdby}}:</b>&nbsp;
                                            {{item.replyDescription}}
                                            <div class="reply-msg-time">
                                                {{item.createdon}}
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                    <br />
                                </div>
                            </div>

                            <div *ngIf="isMasterAdmin || queryDetails?.reply?.length" class="form-group col-md-12">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>{{'formFields.reply' | translate}}</mat-label>
                                    <textarea matInput autocomplete="off" [rows]="5"
                                        placeholder="{{'formFields.reply' | translate}}" tabindex="5"
                                        formControlName="reply" required></textarea>
                                    <mat-error>
                                        <span
                                            *ngIf="(!queryForm.get('reply')?.valid && !queryForm.get('reply')?.errors?.serverError) && !reply">
                                            {{'requiredFields.reply' | translate }}</span>
                                        <span
                                            *ngIf="!queryForm.get('reply')?.errors?.serverError && (reply || (queryForm.get('reply')?.valid && !reply))">
                                            {{'requiredFields.reply' | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-group d-grid gap-2 d-md-flex justify-content-md-end">
                    <button *ngIf="queryDetails.action === 'new'" mat-raised-button color="primary"
                        class="btn-primary mx-3" (click)="saveupdate()">
                        {{'button.create' | translate}}
                    </button>
                    <button *ngIf="queryDetails.action === 'edit' && !isMasterAdmin && !queryDetails?.reply?.length" mat-raised-button color="primary"
                        class="btn-primary mx-3" (click)="saveupdate()">
                        {{'button.update' | translate}}
                    </button>
                    <button *ngIf="queryDetails.action === 'edit' && (isMasterAdmin || queryDetails?.reply?.length)" mat-raised-button color="primary"
                        class="btn-primary mx-3" (click)="sendreply()">
                        {{'button.reply' | translate}}
                    </button>
                    <button mat-raised-button color="secondary" class="btn-secondary"
                        (click)="cancel(promocodeGeneration)">
                        {{'button.cancel' | translate}}
                    </button>
                </div>
            </form>
            

        </div>
      </div>
    </div>
  </div>
  