<div class="ucg-dialog-box">
    <div class="dialog-header-primary">
        <h4 style="margin: 0;">{{data.title}}</h4>
    </div>
    <div class="content-container">
        <div class="flex dialoug-footer">
            <button mat-raised-button color="primary" class="btn-primary btn-popup"
                [mat-dialog-close]="true">Yes</button>
            <button mat-raised-button color="secondary" class="btn-secondary btn-popup"
                [mat-dialog-close]="false">No</button>
        </div>
    </div>
</div>