
<div id="main" class="theme-wrapper" [ngClass]="{'toggle' : toggleClass}"  [class]="'theme-wrapper '" >
  <div class="mat-app-background">
    
    <app-sidenav (sidenavClass)="receiveMessage($event)">
      <main class="main-content border-radius-lg ps">
        <app-content-wrapper>
          <div class="wrapper navHeader">
            <!-- NavBar -->
            <app-navbar></app-navbar>
          </div>
          <router-outlet>
            <!-- registered user layout -->
          </router-outlet>
        </app-content-wrapper>
      </main>
      <app-loader></app-loader>
    </app-sidenav>
    <!-- <app-footer></app-footer> -->
  </div>
  </div>

  <!-- <p class="initial-validation" *ngIf="!loggedIn">Validating your Login... Please wait...</p> -->

