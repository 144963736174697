<div class="ucg-dialog-box">
    <div [ngClass]= "{'dialog-header-success':data.type == 'Success' ,
    'dialog-header-warning':data.type == 'Warning',
    'dialog-header-error':data.type == 'Error' } " class="dialog-header-success">
      <!-- <span class="ucg-success-icon"></span> -->
       <h4>{{data.title}}</h4>
    </div>
    
    <div class="content-container">
        <textarea *ngIf="data.dataType == 'Json'" [(value)]="code" cols=50 rows=10 class="jsonTextarea"></textarea>
        <span *ngIf="data.dataType != 'Json'" class="content-span full-width">{{data.message}}</span>
    </div>
    <div class="flex dialoug-footer mt-2">
        <button mat-raised-button color="secondary" class="btn-primary btn-popup" [mat-dialog-close]="true" (click)="ok()">OK</button>
    </div>
</div>
