<div class="mt-3">
    <div class="card mat-elevation-z mb-3">
        <div class="card-body">
            <h2 class="h2">Transaction Details</h2>
            <form [formGroup]="transactionForm" class="margin-bottom-40 ucg-container">
                
                <div class="row" [ngClass]="{'my-disable':true}">
                    <div class="col-lg-12 col-md-12 col-xs-12 left-section">
                        <div class="form-row">
                            
                            <div class="form-group col-md-6">
                                <!-- [ngClass]="{'has-error' : name && isSubmitClicked}" -->
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>Organization</mat-label>
                                    <input matInput placeholder="Organization" formControlName="tenant">                               
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6">
                                <!-- [ngClass]="{'has-error' : name && isSubmitClicked}" -->
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>Site</mat-label>                                
                                    <input matInput placeholder="Site" formControlName="site"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Charge Point</mat-label>                                                                
                                    <input matInput placeholder="ChargePoint" formControlName="chargePoint"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Connectors</mat-label>                                                              
                                    <input matInput placeholder="Connector" formControlName="connector"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Start Time</mat-label>                                                              
                                    <input matInput placeholder="Start Time" formControlName="startTime"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                    <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>End Time</mat-label>                                                              
                                    <input matInput placeholder="End Time" formControlName="stopTime"> 
                                </mat-form-field>
                            </div>
                            
                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Start Result</mat-label>                                                              
                                    <input matInput placeholder="Start Result" formControlName="startResult"> 
                                </mat-form-field>
                            </div>
                            
                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Stop Reason</mat-label>                                                              
                                    <input matInput placeholder="Stop Reason" formControlName="stopReason"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Meter Start</mat-label>                                                              
                                    <input matInput placeholder="Meter Start" formControlName="meterStart"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Meter Stop</mat-label>                                                              
                                    <input matInput placeholder="Meter Stop" formControlName="meterEnd"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Customer Name</mat-label>                                                              
                                    <input matInput placeholder="Customer Name" formControlName="customerName"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Customer Email</mat-label>                                                              
                                    <input matInput placeholder="Customer Email" formControlName="customerEmail"> 
                                </mat-form-field>
                            </div>
                            
                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Mobile Number</mat-label>                                                              
                                    <input matInput placeholder="Mobile Number" formControlName="cellphonenumber"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Actual Amount</mat-label>                                                              
                                    <input matInput placeholder="Actual Amount" formControlName="subtotal"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Discount Amount</mat-label>                                                              
                                    <input matInput placeholder="Discount Amount" formControlName="discountamount"> 
                                </mat-form-field>
                            </div>
                            
                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Total Amount Charged</mat-label>                                                              
                                    <input matInput placeholder="Total Amount Charged" formControlName="amount"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Charging Rate</mat-label>                                                              
                                    <input matInput placeholder="Charging Rate" formControlName="chargingRate"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Markup Charge</mat-label>                                                              
                                    <input matInput placeholder="Markup Charge" formControlName="markupcharge"> 
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Promo Code</mat-label>                                                              
                                    <input matInput placeholder="Promo Code" formControlName="promocode"> 
                                </mat-form-field>
                            </div>
                            
                        </div>
                    </div>
                    </div>  

                    
                    <div class="btn-group d-grid gap-2 d-md-flex justify-content-md-end">
                        <button mat-raised-button color="secondary" class="btn-secondary mat-elevation-z" (click)="back()">
                            <span class="material-symbols-outlined">keyboard_backspace</span> Back
                        </button>
                    </div>
            
            </form>
        </div>
    </div>
</div>