<div class="mt-3">
    <div class="card mat-elevation-z mb-3">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6 lg-6">
                    <h2 class="h2">Register Charge Point</h2>
                </div>

                

                <div class="col-md-6 lg-6 text-end">
                    <div class="head-right-outer">
                        <a class="btn-border mat-elevation-z icon-right" mat-raised-button
                        data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Filter<span
                                class="material-symbols-outlined">keyboard_arrow_down</span></a>
                        <a (click)="navigateCharger()" class="btn-border mat-elevation-z" mat-raised-button
                            href="javascript:;"><span class="material-symbols-outlined">add</span>
                            {{'button.newCharger' | translate}}</a>
                        <a class="btn-back mat-elevation-z" mat-raised-button href="javascript:;"><span
                                class="material-symbols-outlined">keyboard_backspace</span> Back</a>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-4">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Organization</mat-label>
                        <mat-select [(ngModel)]="selectedTenant" tabindex="1"
                            (selectionChange)="tenantSelection($event.value)">
                            <mat-option *ngFor="let tenant of tenants" [value]="tenant">
                                {{tenant.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Site</mat-label>
                        <mat-select [(ngModel)]="selectedSite" tabindex="2" [disabled]="!selectedTenant"
                            (selectionChange)="getChargePoints()">
                            <mat-option *ngFor="let site of sites" [value]="site">
                                {{site.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="devider mb-3"></div>
            <div class="filter-outer mt-0">
                <div class="collapse" id="multiCollapseExample1">
                <div class="row">
                    <div class="col-md-2 lg-2">                        
                            <mat-form-field appearance="outline" class="w-100 ucg-input">
                                <mat-label>Filter by ChargePointId</mat-label>
                                <input type="text" matInput [formControl]="chargePointIdControl">
                            </mat-form-field>
                    </div>
                    <div class="col-md-2 lg-2">
                        
                            <mat-form-field appearance="outline" class="w-100 ucg-input">
                                <mat-label>Filter by ChargerType</mat-label>
                                <input type="text" matInput [formControl]="chargeTypeControl">
                            </mat-form-field>
                    </div>
                    <div class="col-md-3 lg-3">
                        
                            <mat-form-field appearance="outline" class="w-100 ucg-input">
                                <mat-label>Filter by Status</mat-label>
                                <input type="text" matInput [formControl]="statusControl">
                            </mat-form-field>
                      
                    </div>
                    <div class="col-md-3 lg-3">
                        <div class="form-check form-switch">
                            <mat-slide-toggle [color]="'primary'" (change)="toggleDeletedRecords()">Show
                                Deleted Records</mat-slide-toggle>
                            <!-- <input class="form-check-input" (change)="toggleDeletedRecords()" type="checkbox"
                                id="flexSwitchCheckDefault" checked="">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Show Deleted Records</label> -->
                        </div>
                    </div>
                    <div class="col-md-2 lg-2">
                        <a href="javascript:;" mat-raised-button class="reset-btn mat-elevation-z"
                            (click)="resetFilters()"><span class="material-symbols-outlined">restart_alt</span>
                            Reset</a>
                    </div>
                </div>
                </div>
            </div>
            <div class="body-content-outer">
                <div class="row">
                    <div class="col-md-12 lg-12">

                        <div class="ucg-table">
                            <mat-table #table [dataSource]="dataSource" matSort matSortDirection="asc">

                                <ng-container matColumnDef="registrationDate">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.registrationDate |
                                        date:"MM-dd-yyyy h:mm a"}} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="chargePointId">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{'table.header.chargePointId' | translate}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" [ngClass]="{'disable-link': deletedRecords}"
                                        (click)="chargeEdit(element);" style="cursor:pointer;">
                                        {{element.chargePointId}} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="chargerType">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{'table.header.chargerType' | translate}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.chargerType}} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="availabilityStatus">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Availability Status </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.availabilityStatus}} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="numberOfConnectors">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="alignCenter Col-number wd-100">
                                        {{'table.header.noOfConnectors' | translate}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="alignCenter Col-number wd-100"> {{element.numberOfConnectors}}
                                    </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="connectortype1">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="alignCenter">
                                        {{'table.header.connectortype1' | translate}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="alignCenter"> {{element.connectortype1}}
                                    </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="connectortype2">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="alignCenter">
                                        {{'table.header.connectortype2' | translate}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="alignCenter"> {{element.connectortype2}}
                                    </mat-cell>
                                </ng-container>
            

                             

                                
                    <ng-container matColumnDef="action" style="cursor: default;cursor:pointer;">
                        <mat-header-cell *matHeaderCellDef class="spc-action wd-200 action-column"
                            [ngClass]="{'disable-link hide': deletedRecords}"> Action </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="wd-200 action-column"
                            [ngClass]="{'disable-link hide': deletedRecords}">
                            <!-- <img matTooltip="Chargepoint Details" src="/assets/zoom-in.svg"
                                (click)="chargeEdit(element);" /> -->
                            <div class="table-action">
                                <a  href="javascript:;" mat-mini-fab color="success"
                                matTooltip="Chargepoint Details" (click)="chargeEdit(element)"
                                class="table-action-btn">
                                    <span class="material-symbols-outlined">undo</span>
                                </a>
                                <a *ngIf="showTransfer" matTooltip="Transfer Chargepoint" mat-mini-fab color="primary" href="javascript:;" (click)="transferChargepoint(element);" class="table-action-btn">
                                    <span class="material-symbols-outlined">info</span>
                                </a>
                                <a *ngIf="!element.isrequestraised" href="javascript:;" mat-mini-fab color="warn" matTooltip="Delete Chargepoint" (click)="deleteChargePoint(element)" class="table-action-btn">
                                <span class="material-symbols-outlined">delete</span>
                                </a>
                                <a  href="javascript:;" mat-mini-fab color="success"  *ngIf="element.isrequestraised && !deletedRecords"
                                matTooltip="Undo Delete Chargepoint" (click)="unDeleteChargePoint(element)"
                                class="table-action-btn">
                                    <span class="material-symbols-outlined">undo</span>
                                </a>
                            </div>
                        </mat-cell>
                    </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            </mat-table>
                            <div *ngIf="process" class="text-center norecord table-loader">
                               
                                <div class="loader-inner">
                                    <mat-spinner [diameter]="40"></mat-spinner>
                                    <small>Loading</small>
                                </div>
                            </div>
                            <div *ngIf="!process && (dataSource.data.length == 0 || dataSource.filteredData.length == 0)"
                                class="norecord">
                                {{'table.noRecordFound' | translate}}</div>
                            <mat-paginator #paginator [length]="totalCount" [pageIndex]="pageNumber"
                                [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
                                (page)="pageChanged($event)">
                            </mat-paginator>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Old -->

