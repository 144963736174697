<!-- NEW Design -->
<div class="mt-3">
    <div class="card mat-elevation-z mb-3">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6 lg-6">
                    <h2 class="h2">PromoCode Details Report</h2>
                </div>
                <!-- <div *ngIf="createTenant" class="col-md-6 lg-6 text-end">
            <span class="creation-button">
              <button mat-raised-button color="primary" class="btn-primary"
                (click)="navigateTenant();">{{'button.newTenant' | translate}}</button>
            </span>
          </div> -->

            </div>
            <div class="filter-outer">
                <form [formGroup]="promocodeForm" #promocodeGeneration="ngForm" class="margin-bottom-40" autocomplete="off">
                <div class="row">
                    <div class="col-md-3 lg-3">

                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                            <mat-label>{{'formFields.startDate' | translate}}</mat-label>
                            <input matInput [matDatepicker]="startdatepicker" autocomplete="off"
                                placeholder="{{'formFields.startDate' | translate}}" tabindex="1"
                                formControlName="startDate" [max]="maxDate" required>
                            <mat-datepicker-toggle matSuffix [for]="startdatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #startdatepicker></mat-datepicker>
                            <mat-error>
                                <span
                                    *ngIf="(!promocodeForm.get('startDate')?.valid && !promocodeForm.get('startDate')?.errors?.serverError) && !startDate">
                                    {{'requiredFields.startDate' | translate }}</span>
                                <span
                                    *ngIf="!promocodeForm.get('startDate')?.errors?.serverError && (startDate || (promocodeForm.get('startDate')?.valid && !startDate))">
                                    {{'requiredFields.startDate' | translate }}</span>
                            </mat-error>
                            <mat-error>
                                <span *ngIf="promocodeForm.get('startDate')?.errors?.serverError | translate">
                                    {{promocodeForm.get('startDate')?.errors?.serverError |
                                    translate}}</span>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-3 lg-3">
                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                            <mat-label>{{'formFields.endDate' | translate}}</mat-label>
                            <input matInput [matDatepicker]="enddatepicker" autocomplete="off"
                                placeholder="{{'formFields.endDate' | translate}}" tabindex="1"
                                formControlName="endDate" [disabled]="!promocodeForm.get('startDate')?.value"
                                [min]="promocodeForm.get('startDate')?.value" [max]="maxDate" required>
                            <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #enddatepicker></mat-datepicker>
                            <mat-error>
                                <span
                                    *ngIf="(!promocodeForm.get('endDate')?.valid && !promocodeForm.get('endDate')?.errors?.serverError) && !endDate">
                                    {{'requiredFields.endDate' | translate }}</span>
                                <span
                                    *ngIf="!promocodeForm.get('endDate')?.errors?.serverError && (endDate || (promocodeForm.get('endDate')?.valid && !endDate))">
                                    {{'requiredFields.endDate' | translate }}</span>
                            </mat-error>
                            <mat-error>
                                <span *ngIf="promocodeForm.get('endDate')?.errors?.serverError | translate">
                                    {{promocodeForm.get('endDate')?.errors?.serverError |
                                    translate}}</span>
                            </mat-error>
                        </mat-form-field>



                    </div>
                    <div class="col-md-2">
                        <button mat-raised-button color="primary" class="btn-primary mt-02 w-100"
                            (click)="getReport()">
                            View Report</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                            <mat-label>Select Organization</mat-label>
                            <mat-select formControlName="tenants" multiple tabindex="1" required>
                                <mat-option (click)="toggleAllTenantSelection()" value="select-all">Select
                                    All</mat-option>
                                <mat-option *ngFor="let tenant of tenants" [value]="tenant.tenantId"
                                    (click)="tenantSelection()">
                                    {{tenant.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                <span *ngIf="!promocodeForm.get('tenants')?.valid">
                                    Organization name is required.</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                            <mat-label>Select Site</mat-label>
                            <mat-select formControlName="sites" multiple tabindex="1" required>
                                <mat-option (click)="toggleAllSiteSelection()" value="select-all">Select
                                    All</mat-option>
                                <mat-option *ngFor="let site of sites" [value]="site.siteId" (click)="siteSelection()">
                                    {{site.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                <span *ngIf="!promocodeForm.get('sites')?.valid">
                                    Site is required.</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                            <mat-label>Select Charge Points</mat-label>
                            <mat-select formControlName="chargePoints" multiple tabindex="1" required>
                                <mat-option (click)="toggleAllChargePointSelection()" value="select-all">Select
                                    All</mat-option>
                                <mat-option *ngFor="let chargePoint of chargePoints" [value]="chargePoint.chargePointId"
                                    (click)="chargePointSelection()">
                                    {{chargePoint.chargePointId}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                <span *ngIf="!promocodeForm.get('chargePoints')?.valid">
                                    Charge points are required.</span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3 lg-3">
                        <a [disabled]="dataSource.data.length === 0" href="javascript:;" mat-raised-button class="reset-btn mat-elevation-z" (click)="downloadReport()"><span class="material-symbols-outlined">restart_alt</span> Download</a>
                    </div>
                </div>
            </form>
            </div>
            <div class="body-content-outer">
                <div class="row">
                    <div class="col-md-12 lg-12">

                        <div class="ucg-table">

                            <mat-table #table [dataSource]="dataSource" matSort matSortDirection="asc">
                                <!-- matSortActive="name" -->
                                <ng-container matColumnDef="tenant">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Organization </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{ element.tenant }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="site">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Site </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{ element.site }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="chargePoint">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> ChargePoint </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{ element.chargePoint }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="customerId">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Customer ID </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{ element.customerId }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="noofTimesUsed">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> No. of Times Used </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{ element.noofTimesUsed }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="promocode">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Promocode </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{ element.promocode }} </mat-cell>
                                </ng-container>
            
                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            </mat-table>
                            <div *ngIf="process" class="text-center norecord table-loader">
                               
                                <div class="loader-inner">
                                  <mat-spinner [diameter]="40"></mat-spinner>
                                  <small>Loading</small>
                                </div>
                              </div>
                            <div *ngIf="!process && (dataSource.data.length == 0 || dataSource.filteredData.length == 0)"
                                class="norecord">
                                {{'table.noRecordFound' | translate}}
                            </div>
                            <mat-paginator *ngIf="dataSource.data.length" #paginator [pageSize]="5"
                                [length]="dataSource.data.length" [pageSizeOptions]="[5, 10, 20]"
                                [showFirstLastButtons]="true">
                            </mat-paginator>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- OLD Design -->
