
    
      <div class="row mt-3">
        <div class="col-lg-12 position-relative z-index-2">
          <!-- <form [formGroup]="siteDashboardForm" #siteDashboard="ngForm" class="margin-bottom-40 ucg-container"> -->
            <div class="card main-filter-card">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-7">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Organization</mat-label>
                    <mat-select (selectionChange)="tenantSelection($event.value)" [(ngModel)]="selectedTenant"
                        tabindex="1">
                        <mat-option *ngFor="let tenant of tenants" [value]="tenant">
                            {{tenant.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
              </div>
            </div>
            <!-- </form> -->
          <div class="row mt-4">
            <div class="col-lg-4 col-sm-4 mt-sm-0 mt-4">
              <div class="card  mb-2 box-1 total-tenants-outer">
                <div class="card-header d-flex">
                  <div class="box-icon-outer text-center">
                    <img src="./assets/img/total-tenants.svg" />
                  </div>
                  <div class="header-text-outer mt-2" (click)="goToSite()">
                    <h4>{{'cards.sites' | translate }}</h4>
                    <p>{{sitesCount}}</p>
                  </div>
                </div>
                <hr class="dark horizontal my-0">
                <div class="card-footer p-3">
                  <p class="mb-0"><img src="./assets/img/trending_up.svg"><span
                      class="text-success text-sm font-weight-bolder">5%</span>Increase</p>
                </div>
              </div>
            </div>
            
            <div class="col-lg-4 col-sm-4 mt-sm-0 mt-4">
              <div class="card  mb-2 box-1 total-charge-points">
                <div class="card-header d-flex">
                  <div class="box-icon-outer text-center">
                    <img src="./assets/img/total-charge-points.svg" />
                  </div>
                  <div class="header-text-outer mt-2" routerLink="/connectors/All">
                    <h4>{{'cards.chargePoints' | translate }}</h4>
                    <p>{{chargePointsCount}}</p>
                  </div>
                </div>
                <hr class="dark horizontal my-0">
                <div class="card-footer p-3">
                  <p class="mb-0"><img src="./assets/img/trending_up.svg"><span
                      class="text-success font-weight-bolder">5%</span>Increase</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-4 mt-sm-0 mt-4">
              <div class="card  mb-2 box-1 offline-charge-points">
                <div class="card-header d-flex">
                  <div class="box-icon-outer text-center">
                    <img src="./assets/img/offline-charge-points.svg" />
                  </div>
                  <div class="header-text-outer mt-2" routerLink="/connectors/Offline">
                    <h4>Off. Charge Points</h4>
                    <p>{{connectorsOfflineCount}}</p>
                  </div>
                </div>
                <hr class="dark horizontal my-0">
                <div class="card-footer p-3">
                  <p class="mb-0"><img src="./assets/img/trending_up.svg"><span
                  class="text-success font-weight-bolder">5%</span>Increase</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5 box-2">
            <div class="col-lg-6 box-2-2">
              <div class="card z-index-2">
                <div class="card-body pt-0">
                  <div class="border-radius-lg">
                    
                    <div class="chart">
                      <div *ngIf="chartData.length" class="chartjs-container">
                        <canvas id="ChartJs" baseChart [datasets]="chartData" [labels]="chartLabel"
                            [options]="chartOptions" [colors]="chartColors" [legend]="chartLegend"
                            [chartType]="'bar'" [plugins]="chartPlugins">
                            <!-- (chartHover)="chartHovered($event)" 
                            (chartClick)="chartClicked($event)" -->
                        </canvas>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="text-center mt-n3 mx-3 Connectors-img-outer">
                  <img src="./assets/img/Connectors-img.svg" />
                </div>
                <div class="card-body p-3 connectors-footer">
                  <!-- <div class="row connectors-footer-contect">
                    <div class="col-lg-4 col-md-4" routerLink="/connectors/Online">
                      <label><span class="available" >{{connectorsAvailableCount}}</span>Available</label>
                    </div>
                    <div class="col-lg-4 col-md-4" routerLink="/connectors/Occupied">
                      <label><span class="unavailable">{{connectorsInUseCount}}</span>Occupied</label>
                    </div>
                    <div class="col-lg-4 col-md-4" routerLink="/connectors/Unavailable">
                      <label><span class="occupied">{{connectorsOfflineCount}}</span>Unavailable</label>
                    </div>
                  </div> -->
                  <div class="cn-status-inner">
                    <h3>Connectors</h3>
                    <div class="connectors-footer-contect">
                      <label class="available">
                        <span class="material-symbols-outlined">check_circle</span>
                        <p>{{connectorsAvailableCount}}</p>
                        Available
                      </label>
                      <label class="unavailable">
                        <span class="material-symbols-outlined">change_circle</span>
                        <p>{{connectorsInUseCount}}</p>
                        Occupied
                      </label>
                      <label class="occupied">
                        <span class="material-symbols-outlined">error</span>
                        <p>{{connectorsOfflineCount}}</p>
                        Unavailable
                      </label>
                      <label class="faulted">
                        <span class="material-symbols-outlined">disabled_by_default</span>
                        <p>0</p>
                        Faulted
                      </label>
                      <label class="preparing">
                        <span class="material-symbols-outlined">ev_charger</span>
                        <p>0</p>
                        Preparing
                      </label>
                      <label class="authorized">
                        <span class="material-symbols-outlined">account_circle</span>
                        <p>0</p>
                        Authorized
                      </label>
                      <label class="finishing">
                        <span class="material-symbols-outlined">done_all</span>
                        <p>0</p>
                        Finishing
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5 box-3">
            <div class="col-lg-3 col-md-3 box-3-1">
              <div class="card z-index-2">
                <div class="card-body pt-0">
                  <div class="border-radius-lg">
                    <div class="box-3-header d-flex align-items-start">
                      <div class="icon-outer">
                        <img src="./assets/img/currency_exchange.svg">
                      </div>
                      <div class="text-outer">
                        <h4>Tickets</h4>
                        <p>26</p>
                      </div>
                    </div>
                    <div class="box-3-body">
                      <p>Lorem ispum doler sit.</p>
                      <p>Lorem ispum doler sit.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 box-3-2">
              <div class="card z-index-2">
                <div class="card-body pt-0">
                  <div class="border-radius-lg">
                    <div class="box-3-header d-flex align-items-start">
                      <div class="icon-outer">
                        <img src="./assets/img/currency_exchange.svg">
                      </div>
                      <div class="text-outer">
                        <h4>Tickets</h4>
                        <p>26</p>
                      </div>
                    </div>
                    <div class="box-3-body">
                      <p>Lorem ispum doler sit.</p>
                      <p>Lorem ispum doler sit.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 box-3-3">
              <div class="card z-index-2">
                <div class="card-body pt-0">
                  <div class="border-radius-lg">
                    <div class="box-3-header d-flex align-items-start">
                      <div class="icon-outer">
                        <img src="./assets/img/currency_exchange.svg">
                      </div>
                      <div class="text-outer">
                        <h4>Tickets</h4>
                        <p>26</p>
                      </div>
                    </div>
                    <div class="box-3-body">
                      <p>Lorem ispum doler sit.</p>
                      <p>Lorem ispum doler sit.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 box-3-4">
              <div class="card z-index-2">
                <div class="card-body pt-0">
                  <div class="border-radius-lg">
                    <div class="box-3-header d-flex align-items-start">
                      <div class="icon-outer">
                        <img src="./assets/img/currency_exchange.svg">
                      </div>
                      <div class="text-outer">
                        <h4>Tickets</h4>
                        <p>26</p>
                      </div>
                    </div>
                    <div class="box-3-body">
                      <p>Lorem ispum doler sit.</p>
                      <p>Lorem ispum doler sit.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5 box-4">
            <div class="col-lg-12 col-md-12">
              <div class="card z-index-2">
                <div class="card-body">
                  <div class="border-radius-lg d-flex">
                    <div class="box-4-left mr-auto">
                      <h2>Reduced <span>Co2</span> Emission</h2>
                    </div>
                    <div class="box-4-right">
                      <ul>
                        <li>
                          <img src="./assets/img/ENERGY.svg">
                          <p>ENERGY</p>
                        </li>
                        <li>
                          <img src="./assets/img/ENVIRONMENT.svg">
                          <p>ENVIRONMENT</p>
                        </li>
                        <li>
                          <img src="./assets/img/ECO.svg">
                          <p>ECO</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div class="row mt-5 box-5">
            <div class="col-lg-12 col-md-12">
              <img src="./assets/img/map.svg">
            </div>
          </div>
        </div>
      </div>
      <footer class="footer mt-4">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-12 mb-lg-0 text-center">
              <p>Copyrights © 2023 Universal EV Chargers  | Designed by Cognisun</p>
            </div>
          </div>
        </div>
      </footer>
    



