


<!-- New Design -->

<div class="mt-3">
    <div class="card mat-elevation-z mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 lg-6">
            <h2 class="h2">{{'allTypeContent.siteCreation' | translate}}</h2>
          </div>
          <div class="col-md-6">
            <h4 class="margin-top-16 textMaxLength" style="text-align: right;">{{'allTypeContent.tenant' |
                translate}}: {{'singleBinding.tenantName' | translate : {tenantName:tenantName} }}</h4>
        </div>
          <!-- <div *ngIf="createTenant" class="col-md-6 lg-6 text-end">
            <span class="creation-button">
              <button mat-raised-button color="primary" class="btn-primary"
                (click)="navigateTenant();">{{'button.newTenant' | translate}}</button>
            </span>
          </div> -->

        </div>
        
        <div class="body-content-outer">
            <form [formGroup]="siteForm" #siteRegistration="ngForm" class="margin-bottom-40 ucg-container" autocomplete="off">

                <div class="row">
                    <div class="col-lg-12 col-md-12 col-xs-12 left-section">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <!-- [ngClass]="{'has-error' : name && isSubmitClicked}" -->
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>{{'formFields.name' | translate}}</mat-label>
                                    <input matInput placeholder="{{'formFields.name' | translate}}" tabindex="1" maxlength="150"
                                        autocomplete="off" min="3" max="150" formControlName="name" required>
                                    <mat-error>
                                        <span
                                            *ngIf="(!siteForm.get('name')?.valid && !siteForm.get('name')?.errors?.serverError) && !name">
                                            {{'requiredFields.name' | translate }}</span>
                                        <span
                                            *ngIf="!siteForm.get('name')?.errors?.serverError && (name || (siteForm.get('name')?.valid && !name))">
                                            {{'requiredFields.nameLength' | translate }}</span>
                                    </mat-error>
                                    <mat-error>
                                        <span *ngIf="siteForm.get('name')?.errors?.serverError">
                                            {{siteForm.get('name')?.errors?.serverError | translate}}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-6">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>{{'formFields.address' | translate}}</mat-label>
                                    <input matInput placeholder="{{'formFields.address' | translate}}" tabindex="9"
                                        autocomplete="nope" formControlName="street" required>
                                    <mat-error>
                                        <span
                                            *ngIf="(!siteForm.get('street')?.errors?.serverError && !siteForm.get('street')?.valid) && !street">
                                            {{'requiredFields.street' | translate }}</span>
                                        <span
                                            *ngIf="!siteForm.get('street')?.errors?.serverError && (street || (siteForm.get('street')?.valid && !street))">
                                            {{'requiredFields.streetLength' | translate }}</span>
                                    </mat-error>
                                    <mat-error>
                                        <span *ngIf="siteForm.get('street')?.errors?.serverError">
                                            {{siteForm.get('street')?.errors?.serverError| translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'formFields.status' | translate }}</mat-label>
                                    <mat-select formControlName="status" tabindex="2">
                                        <mat-option>{{'formFields.status' | translate }}</mat-option>
                                        <mat-option value="{{'formFields.active' | translate }}">{{'formFields.active' |
                                            translate }}</mat-option>
                                        <mat-option value="{{'formFields.inactive' | translate }}">{{'formFields.inactive' |
                                            translate }}</mat-option>
                                    </mat-select>
        
                                    <mat-error>
                                        <span *ngIf="siteForm.get('status')?.errors?.serverError">
                                            {{siteForm.get('status')?.errors?.serverError | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-6">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>{{'formFields.city' | translate }}</mat-label>
                                    <input matInput placeholder="{{'formFields.city' | translate }}" tabindex="10"
                                        autocomplete="off" maxlength="100" min="2" max="100" formControlName="city" required>
                                    <mat-error>
                                        <span
                                            *ngIf="(!siteForm.get('city')?.errors?.serverError && !siteForm.get('city')?.valid) && !city">
                                            {{'requiredFields.city' | translate }}</span>
                                        <span
                                            *ngIf="!siteForm.get('city')?.errors?.serverError && (city || (siteForm.get('city')?.valid && !city))">
                                            {{'requiredFields.cityLength' | translate }}</span>
                                    </mat-error>
                                    <mat-error>
                                        <span *ngIf="siteForm.get('city')?.errors?.serverError">
                                            {{siteForm.get('city')?.errors?.serverError | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-2 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'formFields.level2Rate' | translate }}</mat-label>
                                    <input matInput tabindex="3" autocomplete="off" type="text" ThreeDigitDecimalNumber
                                        formControlName="level2Rate" required placeholder="{{'formFields.level2Rate' | translate }}" onkeyup="
                                        var date = this.value;
                                        if (event.keyCode !== 8 && date.match(/^\d{3}$/) !== null) {
                                           this.value = date + '.';
                                        }" max="7" maxlength="7"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || ( event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)))">
        
                                    <mat-error>
                                        <span *ngIf="(!siteForm.get('level2Rate')?.valid)">
                                            {{'requiredFields.level2Rate' | translate }}</span>
                                        <span *ngIf="siteForm.get('level2Rate')?.errors?.serverError">
                                            {{siteForm.get('level2Rate')?.errors?.serverError | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div *ngIf="canModifyMarkupCharge" class="form-group col-md-2 ucg-input">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>{{'formFields.markupCharge' | translate}}</mat-label>
                                    <input matInput autocomplete="off" type="text" tabindex="14"
                                        formControlName="markupCharge"
                                        placeholder="{{'formFields.markupCharge' | translate}}" ThreeDigitDecimalNumber
                                        (change)="validateMarkupThreshold($event)" onkeyup="
                                var date = this.value;
                                if (event.keyCode !== 8 && date.match(/^\d{3}$/) !== null) {
                                   this.value = date + '.';
                                }" max="6" maxlength="6"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || ( event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)))">
                                    <mat-error>
                                        <span *ngIf="markupError">Markup must be less than {{markupThreshold}}%</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-2 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'formFields.RateUnit' | translate }}</mat-label>
                                    <mat-select formControlName="level2RateUnit" tabindex="4" autocomplete="off" required>
                                        <mat-option>{{'formFields.RateUnit' | translate }}</mat-option>
                                        <mat-option value="{{'formFields.KWH' | translate }}">{{'formFields.KWH' | translate }}
                                        </mat-option>
                                        <mat-option value="{{'formFields.min' | translate }}">{{'formFields.min' | translate }}
                                        </mat-option>
                                        <mat-option value="{{'formFields.hr' | translate }}">{{'formFields.hr' | translate }}
                                        </mat-option>
                                    </mat-select>
        
                                    <mat-error>
                                        <span *ngIf="(!siteForm.get('level2RateUnit')?.valid)">
                                            {{'requiredFields.level2RateUnit' | translate }}</span>
                                        <span *ngIf="siteForm.get('level2RateUnit')?.errors?.serverError">
                                            {{siteForm.get('level2RateUnit')?.errors?.serverError | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div *ngIf="!canModifyMarkupCharge" class="form-group col-md-2 ucg-input"></div>
        
                            <div class="form-group col-md-6">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>{{'formFields.state' | translate}}</mat-label>
                                    <input matInput placeholder="{{'formFields.state' | translate}}" tabindex="11"
                                        autocomplete="off" maxlength="100" min="2" max="100" formControlName="state" required>
                                    <mat-error>
                                        <span
                                            *ngIf="(!siteForm.get('state')?.errors?.serverError && !siteForm.get('state')?.valid) && !state">
                                            {{'requiredFields.state' | translate }}</span>
                                        <span
                                            *ngIf="!siteForm.get('state')?.errors?.serverError && (state || (siteForm.get('state')?.valid && !state))">
                                            {{'requiredFields.stateLength' | translate }}</span>
                                    </mat-error>
                                    <mat-error>
                                        <span *ngIf="siteForm.get('state')?.errors?.serverError">
                                            {{siteForm.get('state')?.errors?.serverError | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-2 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'formFields.dcFastRate' | translate }}</mat-label>
                                    <input matInput tabindex="5" autocomplete="off" type="text" ThreeDigitDecimalNumber
                                        formControlName="dcFastRate" required placeholder="{{'formFields.dcFastRate' | translate }}" onkeyup="
                                        var date = this.value;
                                        if (event.keyCode !== 8 && date.match(/^\d{3}$/) !== null) {
                                           this.value = date + '.';
                                        }" max="7" maxlength="7"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || ( event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)))">
        
                                    <mat-error>
                                        <span *ngIf="(!siteForm.get('dcFastRate')?.valid)">
                                            {{'requiredFields.dcFastRate' | translate }}</span>
                                        <span *ngIf="siteForm.get('dcFastRate')?.errors?.serverError">
                                            {{siteForm.get('dcFastRate')?.errors?.serverError | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div *ngIf="canModifyMarkupCharge" class="form-group col-md-2 ucg-input">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>{{'formFields.markupCharge' | translate}}</mat-label>
                                    <input matInput autocomplete="off" type="text" tabindex="14"
                                        formControlName="markupPercentForDC"
                                        placeholder="{{'formFields.markupCharge' | translate}}" ThreeDigitDecimalNumber
                                        (change)="validateDCMarkupThreshold($event)" onkeyup="
                                var date = this.value;
                                if (event.keyCode !== 8 && date.match(/^\d{3}$/) !== null) {
                                   this.value = date + '.';
                                }" max="6" maxlength="6"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || ( event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)))">
                                    <mat-error>
                                        <span *ngIf="markupError">Markup must be less than {{markupThreshold}}%</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-2 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'formFields.RateUnit' | translate }}</mat-label>
                                    <mat-select formControlName="dcFastRateUnit" tabindex="6" autocomplete="off" required>
                                        <mat-option>{{'formFields.RateUnit' | translate }}</mat-option>
                                        <mat-option value="{{'formFields.KWH' | translate }}">{{'formFields.KWH' | translate }}
                                        </mat-option>
                                        <mat-option value="{{'formFields.min' | translate }}">{{'formFields.min' | translate }}
                                        </mat-option>
                                        <mat-option value="{{'formFields.hr' | translate }}">{{'formFields.hr' | translate }}
                                        </mat-option>
                                    </mat-select>
        
                                    <mat-error>
                                        <span *ngIf="(!siteForm.get('dcFastRateUnit')?.valid)">
                                            {{'requiredFields.dcRateUnit' | translate }}</span>
                                        <span *ngIf="siteForm.get('dcFastRateUnit')?.errors?.serverError">
                                            {{siteForm.get('dcFastRateUnit')?.errors?.serverError | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div *ngIf="!canModifyMarkupCharge" class="form-group col-md-2 ucg-input"></div>
        
                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'formFields.country' | translate }}</mat-label>
                                    <mat-select formControlName="country" tabindex="12" required autocomplete="off">
                                        <mat-option *ngFor="let countryName of countryList" [value]="countryName.country">
                                            {{countryName.country}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        <span
                                            *ngIf="(!siteForm.get('country')?.errors?.serverError && !siteForm.get('country')?.valid) && !country">
                                            {{'requiredFields.country' | translate }}</span>
                                        <span
                                            *ngIf="!siteForm.get('country')?.errors?.serverError && (country || (siteForm.get('country')?.valid && !country))">
                                            {{'requiredFields.countryLength' | translate }}</span>
                                    </mat-error>
                                    <mat-error>
                                        <span *ngIf="siteForm.get('country')?.errors?.serverError">
                                            {{siteForm.get('country')?.errors?.serverError | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'formFields.utilityProvider' | translate}}</mat-label>
                                    <input matInput placeholder="{{'formFields.utilityProvider' | translate}}" tabindex="7"
                                        autocomplete="off" type="text" maxlength="10" minlength="3"
                                        formControlName="utilityProvider">
        
                                    <mat-error>
                                        <span
                                            *ngIf="(!siteForm.get('utilityProvider')?.errors?.serverError && !siteForm.get('utilityProvider')?.valid) && !provider">
                                            {{'requiredFields.utilityProvider' | translate }}</span>
                                        <span
                                            *ngIf="!siteForm.get('utilityProvider')?.errors?.serverError && (provider || (siteForm.get('provider')?.valid && !provider))">
                                            {{'requiredFields.utilityProviderLength' | translate }}</span>
                                        <span *ngIf="siteForm.get('utilityProvider')?.errors?.serverError">
                                            {{siteForm.get('utilityProvider')?.errors?.serverError | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'formFields.utilityTDU' | translate}}</mat-label>
                                    <input matInput autocomplete="off" placeholder="{{'formFields.utilityTDU' | translate}}"
                                        tabindex="7" type="text" formControlName="utilitytdu">
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'formFields.zipCode' | translate}}</mat-label>
                                    <input matInput placeholder="{{'formFields.zipCode' | translate}}" tabindex="13" type="text"
                                        autocomplete="off" (keypress)="onKeyPress($event)" maxlength="6" minlength="5"
                                        formControlName="zipCode" required>
                                    <mat-error>
                                        <span
                                            *ngIf="(!siteForm.get('zipCode')?.errors?.serverError && !siteForm.get('zipCode')?.valid) && !siteForm.get('zipCode')?.errors?.pattern && !zip">
                                            {{'requiredFields.zipCode' | translate }}</span>
                                        <span
                                            *ngIf="!siteForm.get('zipCode')?.errors?.serverError && (zip || (siteForm.get('zipCode')?.valid && !zip))">
                                            {{'requiredFields.zipCodeLength' | translate }}</span>
        
                                        <span *ngIf="siteForm.get('zipCode')?.errors?.pattern | translate">
                                            {{'requiredFields.phonePattern' | translate}}</span>
                                    </mat-error>
                                    <mat-error>
                                        <span *ngIf="siteForm.get('zipCode')?.errors?.serverError">
                                            {{siteForm.get('zipCode')?.errors?.serverError | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'formFields.utilityBill' | translate}}</mat-label>
                                    <div style="margin-top: -18px; margin-left: 75px;">
                                        <input [hidden]="true" matInput class="w-100 ucg-input" appearance="outline"
                                            formControlName="utilityBill" autocomplete="off" />
                                        <input type="file" id="fileUpload" tabindex="8" name="utilityBill" class="btn" #myUpload
                                            (change)="onFileChange($event)" accept="{{supportedFileTypes}}" />
                                        <br>
                                        <a *ngIf="hasBillUrl" target="_blank" href="{{imageSource}}">{{fileName}}</a>
                                    </div>
                                    <mat-error>
                                        <span *ngIf="!siteForm.get('utilityBill')?.valid">
                                            {{'requiredFields.utilityBill' | translate }} a</span>
                                        <span *ngIf="siteForm.get('utilityBill')?.errors?.serverError">
                                            {{siteForm.get('utilityBill')?.errors?.serverError | translate }} b</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="form-group col-md-6">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>{{'formFields.phone' | translate}}</mat-label>
                                    <input matInput placeholder="{{'formFields.phone' | translate}}" tabindex="14" type="text"
                                        autocomplete="off" (keypress)="onKeyPress($event)" maxlength="10" min="10" max="10"
                                        formControlName="phone" required>
                                    <mat-error>
                                        <span
                                            *ngIf="(!siteForm.get('phone')?.errors?.serverError && !siteForm.get('phone')?.valid) && !siteForm.get('phone')?.errors?.pattern && !phone">
                                            {{'requiredFields.phone' | translate }}</span>
                                        <span
                                            *ngIf="!siteForm.get('phone')?.errors?.serverError && (phone || siteForm.get('phone')?.valid && !phone)">
                                            {{'requiredFields.phoneLength' | translate }}</span>
        
                                        <span *ngIf="siteForm.get('phone')?.errors?.pattern | translate">
                                            {{'requiredFields.phonePattern' | translate}}</span>
                                    </mat-error>
                                    <mat-error>
                                        <span *ngIf="siteForm.get('phone')?.errors?.serverError">
                                            {{siteForm.get('phone')?.errors?.serverError | translate }}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div *ngIf="canModifyPreAuth" class="form-group col-md-6">
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>{{'formFields.preAuthAmount' | translate}}</mat-label>
                                    <input matInput autocomplete="off" type="text" tabindex="14" formControlName="preAuthAmount"
                                        placeholder="{{'formFields.preAuthAmount' | translate}}" ThreeDigitDecimalNumber onkeyup="
                                        var date = this.value;
                                        if (event.keyCode !== 8 && date.match(/^\d{3}$/) !== null) {
                                           this.value = date + '.';
                                        }" max="6" maxlength="6"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || ( event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)))">
                                </mat-form-field>
                            </div>
        
                        </div>
                    </div>
                </div>
        
                <div class="btn-group d-grid gap-2 d-md-flex justify-content-md-end">
                    <button mat-raised-button color="primary" class="mat-elevation-z btn-icon" (click)="saveSite();">
                        <span class="material-symbols-outlined">check</span> {{'button.save' | translate }}
                    </button>
                    <button mat-raised-button color="secondary" class="btn-secondary mat-elevation-z" (click)="cancel();">
                        <span class="material-symbols-outlined">close</span> {{'button.cancel' | translate }}
                    </button>
                </div>
        
            </form>
        </div>
      </div>
    </div>
  </div>
  
  <!-- OLD Design -->
  
