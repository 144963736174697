<!-- my-loader.component.html -->
<div class="progress-loader" [hidden]="!loading">
  <div class="loading-spinner">
    <!-- <img src="/assets/icons/blue-loading-trans.svg"/>
      <span class="loading-message">Please wait...</span> -->
      <svg class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
  </div>
</div>
