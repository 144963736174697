<!-- NEW Design -->
<div class="mt-3">
    <div class="card mat-elevation-z mb-3">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6 lg-6">
                    <h2 class="h2">Financial Revenue Report</h2>
                </div>
                <!-- <div *ngIf="createTenant" class="col-md-6 lg-6 text-end">
            <span class="creation-button">
              <button mat-raised-button color="primary" class="btn-primary"
                (click)="navigateTenant();">{{'button.newTenant' | translate}}</button>
            </span>
          </div> -->

            </div>
            <div class="filter-outer">
                <div class="row">
                    <div class="col-md-3 lg-3">

                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                            <mat-label>Select Start Date</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" [max]="maxDate" required
                                readonly (dateChange)="getReport()">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                    </div>
                    <div class="col-md-3 lg-3">
                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                            <mat-label>Select End Date</mat-label>
                            <input matInput [matDatepicker]="enddatepicker" [(ngModel)]="endDate"
                                [disabled]="!startDate" [max]="maxDate" (dateChange)="getReport()" [min]="startDate"
                                required readonly>
                            <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #enddatepicker></mat-datepicker>
                        </mat-form-field>

                    </div>
                    <div class="col-md-3 lg-3">
                        <a [disabled]="dataSource.data.length === 0" href="javascript:;" mat-raised-button class="reset-btn mat-elevation-z" (click)="downloadReport()"><span class="material-symbols-outlined">restart_alt</span> Download</a>
                    </div>
                </div>
            </div>
            <div class="body-content-outer">
                <div class="row">
                    <div class="col-md-12 lg-12">

                        <div class="ucg-table">

                            <mat-table #table [dataSource]="dataSource" matSort matSortDirection="asc">
                                <!-- matSortActive="tenant" -->
                                <ng-container matColumnDef="tenant">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Organization </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{ element.tenant }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="site">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Site </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{ element.site }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="chargePoint">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> ChargePoint </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{ element.chargePoint }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="transactionDate">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{ element.transactionDate }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="kwh">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Consumed (KWH)</mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="just-cent"> {{ element.kwh.toFixed(2) }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="amount">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="just-cent"> {{ element.amount.toFixed(2) }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="transactionfees">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Fees </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="just-cent"> {{ element.transactionfees.toFixed(2) }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="utilityFees">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Utility Fees </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="just-cent"> {{ element.utilityFees.toFixed(2) }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="netRevenue">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Revenue </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="just-cent"> {{ element.netRevenue.toFixed(2) }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="revenueShare">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Revenue Share </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="just-cent"> {{ element.revenueShare }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="cloudServiceFees">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Cloud Service Fees </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="just-cent"> {{ element.cloudServiceFees.toFixed(2) }} </mat-cell>
                                </ng-container>
            
                                <ng-container matColumnDef="amountOwed">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Amount Owed </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="just-cent"> {{ element.amountOwed }} </mat-cell>
                                </ng-container>
            
                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            </mat-table>
                            <div *ngIf="process" class="text-center norecord table-loader">
                               
                                <div class="loader-inner">
                                  <mat-spinner [diameter]="40"></mat-spinner>
                                  <small>Loading</small>
                                </div>
                              </div>
                            <div *ngIf="!process && (dataSource.data.length == 0 || dataSource.filteredData.length == 0)"
                                class="norecord">
                                {{'table.noRecordFound' | translate}}
                            </div>
                            <mat-paginator *ngIf="dataSource.data.length" #paginator [pageSize]="5"
                                [length]="dataSource.data.length" [pageSizeOptions]="[5, 10, 20]"
                                [showFirstLastButtons]="true">
                            </mat-paginator>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- OLD Design -->
