<aside class="sidenav navbar navbar-vertical navbar-expand-xs border-0 fixed-start ugg-side-bar-color"
  id="sidenav-main">
  <div class="sidenav-header">
    <a class="navbar-brand m-0"
      href=" https://demos.creative-tim.com/material-dashboard-pro/pages/dashboards/analytics.html " target="_blank">
      <img src="./assets/img/UGG-logo.svg" class="navbar-brand-img h-100" alt="main_logo">
    </a>
  </div>
  <hr class="horizontal light">
  <div class="collapse navbar-collapse  w-auto h-auto" id="sidenav-collapse-main">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link text-dark " routerLink="/dashboard">
          <div class="nev-icon dashboard"></div>
          <span class="sidenav-normal  ms-2  ps-1">{{ 'sidebar.dashboard' | translate }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-dark " routerLink="/tenants">
          <div class="nev-icon tenants"></div>
          <span class="sidenav-normal  ms-2  ps-1">{{ 'sidebar.tenants' | translate }}</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="showTransfer">
        <a class="nav-link text-dark " routerLink="/transfer">
          <div class="nev-icon transfer-site"></div>
          <span class="sidenav-normal  ms-2  ps-1">{{ 'sidebar.transferSite' | translate }}</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="showRegisterChargePoint">
        <a class="nav-link text-dark " routerLink="/register-chargepoint">
          <div class="nev-icon register-charge-point"></div>
          <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.register-chargepoint' | translate }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-dark " routerLink="/site-dashboard">
          <div class="nev-icon charge-points"></div>
          <span class="sidenav-normal  ms-2  ps-1">{{
            'sidebar.chargePoints' | translate }}</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="showPromocode">
        <a class="nav-link text-dark " routerLink="/promo-code">
          <div class="nev-icon promo-codes"></div>
          <span class="sidenav-normal  ms-2  ps-1">{{ 'sidebar.promoCode' | translate }}</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="showTransaction">
        <a class="nav-link text-dark " routerLink="/transaction-list">
          <div class="nev-icon transactions"></div>
          <span class="sidenav-normal  ms-2  ps-1">{{ 'sidebar.transactions' | translate }}</span>
        </a>
      </li>

  

      <li class="nav-item" *ngIf="showDRReport || showNDRReport || showDAReport || showFRReport || showPDReport || showCUReport">
        <a data-bs-toggle="collapse" href="#applicationsExamplesreports" class="nav-link text-dark" aria-controls="applicationsExamples" role="button" aria-expanded="false">
          <div class="nev-icon reports"></div>
          <span class="nav-link-text ms-2 ps-1">{{ 'sidebar.reports' | translate }}</span>
        </a>
        <div class="collapse " id="applicationsExamplesreports">
          <ul class="nav ">
            <li class="nav-item " *ngIf="showDRReport">
              <a class="nav-link text-dark" routerLink="/driver-registration-report">
                <span class="sidenav-mini-icon"><span class="material-symbols-outlined">arrow_forward_ios</span></span>
                <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.driver-registration-report' | translate }} </span>
              </a>
            </li>
            <li class="nav-item " *ngIf="showNDRReport">
              <a class="nav-link text-dark" routerLink="/non-registered-driver-report">
                <span class="sidenav-mini-icon"><span class="material-symbols-outlined">arrow_forward_ios</span></span>
                <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.non-registered-driver-report' | translate }}
                </span>
              </a>
            </li>
            <li class="nav-item " *ngIf="showDAReport">
              <a class="nav-link text-dark" routerLink="/driver-activity-report">
                <span class="sidenav-mini-icon"><span class="material-symbols-outlined">arrow_forward_ios</span></span>
                <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.driver-activity-report' | translate }} </span>
              </a>
            </li>
            <li class="nav-item " *ngIf="showFRReport">
              <a class="nav-link text-dark" routerLink="/financial-revenue-report">
                <span class="sidenav-mini-icon"><span class="material-symbols-outlined">arrow_forward_ios</span></span>
                <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.financial-revenue-report' | translate }} </span>
              </a>
            </li>
            <li class="nav-item " *ngIf="showPDReport">
              <a class="nav-link text-dark" routerLink="/promocode-details-report">
                <span class="sidenav-mini-icon"><span class="material-symbols-outlined">arrow_forward_ios</span></span>
                <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.promocode-details-report' | translate }} </span>
              </a>
            </li>
            <li class="nav-item " *ngIf="showCUReport">
              <a class="nav-link text-dark" routerLink="/chargepoint-uptime-report">
                <span class="sidenav-mini-icon"><span class="material-symbols-outlined">arrow_forward_ios</span></span>
                <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.chargepoint-uptime-report' | translate }} </span>
              </a>
            </li>
          </ul>
        </div>
      </li>





      <li class="nav-item" *ngIf="showUser || showRole || showFinancial">
        <a data-bs-toggle="collapse" href="#applicationsExamples" class="nav-link text-dark" aria-controls="applicationsExamples" role="button" aria-expanded="false">
          <div class="nev-icon settings"></div>
          <span class="nav-link-text ms-2 ps-1">{{ 'sidebar.settings' | translate }}</span>
        </a>
        <div class="collapse " id="applicationsExamples">
          <ul class="nav ">
            <li class="nav-item " *ngIf="showUser">
              <a class="nav-link text-dark" routerLink="/users">
                <span class="sidenav-mini-icon"><span class="material-symbols-outlined">arrow_forward_ios</span></span>
                <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.users' | translate }} </span>
              </a>
            </li>
            <li class="nav-item " *ngIf="showRole">
              <a class="nav-link text-dark" routerLink="/roles">
                <span class="sidenav-mini-icon"><span class="material-symbols-outlined">arrow_forward_ios</span></span>
                <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.roles' | translate }} </span>
              </a>
            </li>
            <li class="nav-item " *ngIf="showFinancial">
              <a class="nav-link text-dark" routerLink="/financial">
                <span class="sidenav-mini-icon"><span class="material-symbols-outlined">arrow_forward_ios</span></span>
                <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.financial' | translate }} </span>
              </a>
            </li>
          </ul>
        </div>
      </li>



      <li class="nav-item" >
        <a data-bs-toggle="collapse" href="#applicationsExamplessupport" class="nav-link text-dark" aria-controls="applicationsExamples" role="button" aria-expanded="false">
          <div class="nev-icon support"></div>
          <span class="nav-link-text ms-2 ps-1">{{ 'sidebar.support' | translate }}</span>
        </a>
        <div class="collapse " id="applicationsExamplessupport">
          <ul class="nav ">
            <li class="nav-item " *ngIf="showUser">
              <a class="nav-link text-dark" routerLink="/query">
                <span class="sidenav-mini-icon"> C </span>
                <span class="nav-link-text ms-2 ps-1" *ngIf="!isMasterAdmin">{{ 'sidebar.raiseQuery' | translate
                  }}</span>
                <span class="nav-link-text ms-2 ps-1" *ngIf="isMasterAdmin">{{ 'sidebar.answerQuery' | translate
                  }}</span>
              </a>
            </li>
            <li class="nav-item " *ngIf="showDeletionRequest">
              <a class="nav-link text-dark" routerLink="/deletion-requests">
                <span class="sidenav-mini-icon"><span class="material-symbols-outlined">arrow_forward_ios</span></span>
                <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.deletionRequests' | translate }} </span>
              </a>
            </li>
            <li class="nav-item " *ngIf="ShowChargePointRequest">
              <a class="nav-link text-dark" routerLink="/transfer-requests">
                <span class="sidenav-mini-icon"><span class="material-symbols-outlined">arrow_forward_ios</span></span>
                <span class="sidenav-normal  ms-2  ps-1"> {{ 'sidebar.transferRequests' | translate }} </span>
              </a>
            </li>
          </ul>
        </div>
      </li>

  </ul>
  </div>
</aside>
<ng-content></ng-content>