

<div class="container-fluid mt-4">
    <div class="card mb-5">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <h2 class="h2">New Charger</h2>
                </div>
                <div class="col-md-8 text-right">
                    <h4 class="textMaxLength">Organization: {{tenantName}}/Site: {{siteName}}</h4>
                </div>
            </div>
            <form [formGroup]="chargerForm" #chargerRegistration="ngForm" class="mt-2 ucg-container">
                
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-xs-12 left-section">
                        <div class="form-row">
                            
                            <div class="form-group col-md-6">
                                <!-- [ngClass]="{'has-error' : name && isSubmitClicked}" -->
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>ChargePointId</mat-label>
                                    <input matInput placeholder="ChargePointId" maxlength="20" min="3" max="20"
                                        formControlName="chargePointId" required>
                                    <mat-error>
                                        <span *ngIf="(!chargerForm.get('chargePointId')?.valid && !chargerForm.get('chargePointId')?.errors?.serverError) && !chargerForm.get('chargePointId')?.errors?.pattern && !chargePointId">
                                            ChargePointId is required.</span>
                                            <span *ngIf="chargerForm.get('chargePointId')?.errors?.pattern | translate">
                                                ChargePointId only allowing with alphanumeric.</span> 
                                            <span
                                                *ngIf="!chargerForm.get('chargePointId')?.errors?.serverError && (chargePointId || (chargerForm.get('chargePointId')?.valid && !chargePointId))">
                                                The minimum length of ChargePointId is 3.</span>
                                    </mat-error>
                                    <mat-error>
                                        <span *ngIf="chargerForm.get('chargePointId')?.errors?.serverError">
                                            {{chargerForm.get('chargePointId')?.errors?.serverError}}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6">
                                <!-- [ngClass]="{'has-error' : name && isSubmitClicked}" -->
                                <mat-form-field class="w-100 ucg-input" appearance="outline">
                                    <mat-label>Name</mat-label>
                                    <input matInput placeholder="Name" maxlength="150" min="3" max="150"
                                        formControlName="name" required>
                                    <mat-error>
                                            <span
                                                *ngIf="(!chargerForm.get('name')?.valid && !chargerForm.get('name')?.errors?.serverError) && !name">
                                                {{'requiredFields.name' | translate }}</span>
                                            <span
                                                *ngIf="!chargerForm.get('name')?.errors?.serverError && (name || (chargerForm.get('name')?.valid && !name))">
                                                {{'requiredFields.nameLength' | translate }}</span>
                                        </mat-error>
                                        <mat-error>
                                            <span *ngIf="chargerForm.get('name')?.errors?.serverError">
                                                {{chargerForm.get('name')?.errors?.serverError | translate}}</span>
                                        </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Charge Type</mat-label>
                                    <mat-select formControlName="chargerType" required>
                                        <mat-option>Charge Type</mat-option>
                                        <mat-option value="Level2">Level2</mat-option>
                                        <mat-option value="DC Fast">DC Fast</mat-option>
                                    </mat-select>                                
                                        <mat-error>
                                            <span *ngIf="!chargerForm.get('chargerType')?.valid">
                                                ChargeType is required.</span>
                                            <span *ngIf="!chargerForm.get('chargerType')?.errors?.serverError">
                                                {{chargerForm.get('chargerType')?.errors?.serverError}}</span>                                            
                                        </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Availability Status</mat-label>
                                    <mat-select formControlName="availabilityStatus" required>
                                        <mat-option>Availability Status</mat-option>
                                        <mat-option value="Active">Active</mat-option>
                                        <mat-option value="Inactive">Inactive</mat-option>
                                    </mat-select>                                
                                        <mat-error>
                                            <span *ngIf="!chargerForm.get('availabilityStatus')?.valid">
                                                Availability Status is required.</span>
                                            <span *ngIf="!chargerForm.get('availabilityStatus')?.errors?.serverError">
                                                {{chargerForm.get('availabilityStatus')?.errors?.serverError}}</span>                                            
                                        </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>No Of Connectors</mat-label>
                                    <mat-select formControlName="connector" required>
                                        <mat-option>No Of Connectors</mat-option>
                                        <mat-option value=1>1</mat-option>
                                        <mat-option value=2>2</mat-option>
                                        <!-- <mat-option value=3>3</mat-option>
                                        <mat-option value=4>4</mat-option>
                                        <mat-option value=5>5</mat-option> -->
                                    </mat-select>                                
                                        <mat-error>
                                            <span *ngIf="!chargerForm.get('connector')?.valid">
                                                No of connectors is required.</span>
                                            <span *ngIf="!chargerForm.get('connector')?.errors?.serverError">
                                                {{chargerForm.get('connector')?.errors?.serverError}}</span>                                            
                                        </mat-error>
                                </mat-form-field>
                            </div>

                            <div *ngIf="chargerForm.get('connector')?.value == 1 || chargerForm.get('connector')?.value == 2" class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Connector Type 1</mat-label>
                                    <mat-select formControlName="connectorType1" required>
                                        <mat-option>Connector Type</mat-option>
                                        <mat-option value="J1772">J1772</mat-option>
                                        <mat-option value="CCS">CCS</mat-option>
                                        <mat-option value="CHAdeMO">CHAdeMO</mat-option>
                                        <mat-option value="Tesla">Tesla</mat-option>
                                    </mat-select>                                
                                        <mat-error>
                                            <span *ngIf="!chargerForm.get('connectorType1')?.valid">
                                                Connector type is required.</span>
                                            <span *ngIf="!chargerForm.get('connectorType1')?.errors?.serverError">
                                                {{chargerForm.get('connectorType1')?.errors?.serverError}}</span>                                            
                                        </mat-error>
                                </mat-form-field>
                            </div>

                            <div *ngIf="chargerForm.get('connector')?.value == 2" class="form-group col-md-6 ucg-input">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Connector Type 2</mat-label>
                                    <mat-select formControlName="connectorType2">
                                        <mat-option>Connector Type</mat-option>
                                        <mat-option value="J1772">J1772</mat-option>
                                        <mat-option value="CCS">CCS</mat-option>
                                        <mat-option value="CHAdeMO">CHAdeMO</mat-option>
                                        <mat-option value="Tesla">Tesla</mat-option>
                                    </mat-select>                                
                                        <mat-error>
                                            <span *ngIf="!chargerForm.get('connectorType2')?.valid">
                                                Connector type is required.</span>
                                            <span *ngIf="!chargerForm.get('connectorType2')?.errors?.serverError">
                                                {{chargerForm.get('connectorType2')?.errors?.serverError}}</span>                                            
                                        </mat-error>
                                </mat-form-field>
                            </div>

                        </div>
                    </div>
                    </div>  

                    
                    <div class="btn-group d-grid gap-2 d-md-flex justify-content-md-end">
                        <button mat-raised-button color="primary" class="mat-elevation-z btn-icon" (click)="saveCharger();">
                            <span class="material-symbols-outlined">check</span> Save
                        </button>
                        <button mat-raised-button color="secondary" class="btn-secondary mat-elevation-z" (click)="cancel();">
                            <span class="material-symbols-outlined">close</span> Cancel
                        </button>
                    </div>
            
            </form>
        </div>
    </div>
</div>
