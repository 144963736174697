


<!-- New Design -->

<div class="mt-3">


    <div class="card mat-elevation-z mb-3" >
        <div class="card-body">
            <h2 class="h2"><span>{{'allTypeContent.tenant' |
                translate}}:</span> {{'singleBinding.tenantName' | translate : {tenantName:tenantName} }}</h2>
            <div  id = "Description"> 
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="list-tick">
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                        <p>
                            Address: <b>{{Prestreet}}</b>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="list-tick">
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                        <p>
                            Company: <b>{{Precompany}}</b>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="list-tick">
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                        <p>
                            City: <b>{{Precity}}</b>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="list-tick">
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                        <p>
                            Email: <b>{{Preemail}}</b>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="list-tick">
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                        <p>
                            State: <b>{{Prestate}}</b>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="list-tick">
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                        <p>
                            Phone: <b>{{Prephone}}</b>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="list-tick">
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                        <p>
                            Country: <b>{{Precountry}}</b>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="list-tick">
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                        <p>
                            Status: <b>{{Prestatus}}</b>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="list-tick">
                        <span class="material-symbols-outlined">arrow_forward_ios</span>
                        <p>
                            Zip Code: <b>{{PrezipCode}}</b>
                        </p>
                    </div>
                </div>

                <div class="row charger-btn-outer">
                    <div class="col-lg-12">
                        <a (click) = "showEditForm()" class="btn edit">Edit</a>
                    </div>
                </div>
            </div>
        </div>

            <div id = "EditForm" class="hidedive">
                <form [formGroup]="tenantForm" #tenantDetail="ngForm" class="margin-bottom-40 ucg-container"
                        autocomplete="off">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-xs-12 left-section">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <!-- [ngClass]="{'has-error' : name && isSubmitClicked}" -->
                                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                                            <mat-label>{{'formFields.name' | translate}}</mat-label>
                                            <input matInput autocomplete="off" placeholder="{{'formFields.name' | translate}}"
                                                tabindex="1" maxlength="150" min="3" max="150" formControlName="name" required>
                                            <mat-error>
                                                <span
                                                    *ngIf="(!tenantForm.get('name')?.valid && !tenantForm.get('name')?.errors?.serverError) && !name">
                                                    {{'requiredFields.name' | translate }}</span>
                                                <span
                                                    *ngIf="!tenantForm.get('name')?.errors?.serverError && (name || (tenantForm.get('name')?.valid && !name))">
                                                    {{'requiredFields.nameLength' | translate }}</span>
                                            </mat-error>
                                            <mat-error>
                                                <span *ngIf="tenantForm.get('name')?.errors?.serverError | translate">
                                                    {{tenantForm.get('name')?.errors?.serverError | translate}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <div class="form-group col-md-6">
                                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                                            <mat-label>{{'formFields.address' | translate}}</mat-label>
                                            <input matInput autocomplete="off"
                                                placeholder="{{'formFields.address' | translate}}" tabindex="6" maxlength="250"
                                                max="250" min="1" formControlName="street" required>
                                            <mat-error>
                                                <span
                                                    *ngIf="(!tenantForm.get('street')?.errors?.serverError && !tenantForm.get('street')?.valid) && !street">
                                                    {{'requiredFields.street' | translate }}</span>
                                                <span
                                                    *ngIf="!tenantForm.get('street')?.errors?.serverError && (address || (tenantForm.get('street')?.valid && !address))">
                                                    {{'requiredFields.streetLength' | translate }}</span>
                                            </mat-error>
                                            <mat-error>
                                                <span *ngIf="tenantForm.get('street')?.errors?.serverError | translate">
                                                    {{tenantForm.get('street')?.errors?.serverError | translate}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <div class="form-group col-md-6">
                                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                                            <mat-label>{{'formFields.company' | translate}}</mat-label>
                                            <input matInput autocomplete="off"
                                                placeholder="{{'formFields.company' | translate}}" tabindex="2" maxlength="250"
                                                min="15" max="250" formControlName="company" required>
                                            <mat-error>
                                                <span
                                                    *ngIf="(!tenantForm.get('company')?.valid && !tenantForm.get('company')?.errors?.serverError) && !companyName">
                                                    {{'requiredFields.company' | translate }}</span>
                                                <span
                                                    *ngIf="!tenantForm.get('company')?.errors?.serverError && (companyName || (tenantForm.get('company')?.valid && !companyName))">
                                                    {{'requiredFields.companyLength' | translate }}</span>
                                            </mat-error>
                                            <mat-error>
                                                <span *ngIf="tenantForm.get('company')?.errors?.serverError | translate">
                                                    {{tenantForm.get('company')?.errors?.serverError | translate}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <div class="form-group col-md-6">
                                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                                            <mat-label>{{'formFields.city' | translate}}</mat-label>
                                            <input matInput autocomplete="off" placeholder="{{'formFields.city' | translate}}"
                                                tabindex="7" maxlength="100" min="2" max="100" formControlName="city" required>
                                            <mat-error>
                                                <span
                                                    *ngIf="(!tenantForm.get('city')?.errors?.serverError && !tenantForm.get('city')?.valid) && !city">
                                                    {{'requiredFields.city' | translate }}</span>
                                                <span
                                                    *ngIf="!tenantForm.get('city')?.errors?.serverError && (city || (tenantForm.get('city')?.valid && !city))">
                                                    {{'requiredFields.cityLength' | translate }}</span>
                                            </mat-error>
                                            <mat-error>
                                                <span *ngIf="tenantForm.get('city')?.errors?.serverError | translate">
                                                    {{tenantForm.get('city')?.errors?.serverError | translate}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <div class="form-group col-md-6">
                                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                                            <mat-label>{{'formFields.email' | translate}}</mat-label>
                                            <input matInput autocomplete="off" placeholder="{{'formFields.email' | translate}}"
                                                tabindex="3" formControlName="email" required>
                                            <mat-error>
                                                <span
                                                    *ngIf="(tenantForm.get('email')?.invalid || tenantForm.get('email')?.touched) && !email">
                                                    {{'requiredFields.email' | translate }}</span>
                                                <span
                                                    *ngIf="!tenantForm.get('email')?.errors?.serverError && tenantForm.get('email')?.errors?.email">
                                                    {{'requiredFields.emailInvalid' | translate }}</span>
                                            </mat-error>
                                            <mat-error>
                                                <span *ngIf="tenantForm.get('email')?.errors?.serverError | translate">
                                                    {{tenantForm.get('email')?.errors?.serverError | translate}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <div class="form-group col-md-6">
                                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                                            <mat-label>{{'formFields.state' | translate}}</mat-label>
                                            <input matInput autocomplete="off" placeholder="{{'formFields.state' | translate}}"
                                                tabindex="8" maxlength="100" min="2" max="100" formControlName="state" required>
                                            <mat-error>
                                                <span
                                                    *ngIf="(!tenantForm.get('state')?.errors?.serverError && !tenantForm.get('state')?.valid) && !state">
                                                    {{'requiredFields.state' | translate }}</span>
                                                <span
                                                    *ngIf="!tenantForm.get('state')?.errors?.serverError && (state || (tenantForm.get('state')?.valid && !state))">
                                                    {{'requiredFields.stateLength' | translate }}</span>
                                            </mat-error>
                                            <mat-error>
                                                <span *ngIf="tenantForm.get('state')?.errors?.serverError | translate">
                                                    {{tenantForm.get('state')?.errors?.serverError | translate}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <div class="form-group col-md-6">
                                        <mat-form-field class="w-100 ucg-input" appearance="outline">
                                            <mat-label>{{'formFields.phone' | translate}}</mat-label>
                                            <input matInput autocomplete="off" placeholder="{{'formFields.phone' | translate}}"
                                                tabindex="4" type="text" (keypress)="onKeyPress($event)" maxlength="15" min="10"
                                                max="15" formControlName="phone" required>
                                            <mat-error>
                                                <span
                                                    *ngIf="(!tenantForm.get('phone')?.errors?.serverError && !tenantForm.get('phone')?.valid) && !tenantForm.get('phone')?.errors?.pattern  && !phone">
                                                    {{'requiredFields.phone' | translate }}</span>
                                                <span
                                                    *ngIf="!tenantForm.get('phone')?.errors?.serverError && (phone || tenantForm.get('phone')?.valid && !phone)">
                                                    {{'requiredFields.phoneLength' | translate }}</span>
                                                <span *ngIf="tenantForm.get('phone')?.errors?.pattern | translate">
                                                    {{'requiredFields.phonePattern' | translate}}</span>
                                            </mat-error>
                                            <mat-error>
                                                <span *ngIf="tenantForm.get('phone')?.errors?.serverError | translate">
                                                    {{tenantForm.get('phone')?.errors?.serverError | translate}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <div class="form-group col-md-6 ucg-input">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>{{'formFields.country' | translate }}</mat-label>
                                            <mat-select autocomplete="off" formControlName="country" tabindex="9" required>
                                                <mat-option *ngFor="let countryName of countryList"
                                                    [value]="countryName.country">
                                                    {{countryName.country}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                <span
                                                    *ngIf="(!tenantForm.get('country')?.errors?.serverError && !tenantForm.get('country')?.valid) && !country">
                                                    {{'requiredFields.country' | translate }}</span>
                                                <span
                                                    *ngIf="!tenantForm.get('country')?.errors?.serverError && (country || (tenantForm.get('country')?.valid && !country))">
                                                    {{'requiredFields.countryLength' | translate }}</span>
                                            </mat-error>
                                            <mat-error>
                                                <span *ngIf="tenantForm.get('country')?.errors?.serverError">
                                                    {{tenantForm.get('country')?.errors?.serverError | translate }}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <div class="form-group col-md-6 ucg-input">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>{{'formFields.status' | translate }}</mat-label>
                                            <mat-select autocomplete="off" formControlName="status" tabindex="5">
                                                <mat-option>{{'formFields.status' | translate }}</mat-option>
                                                <mat-option value="{{'formFields.active' | translate }}">{{'formFields.active' |
                                                    translate }}</mat-option>
                                                <mat-option value="{{'formFields.pending' | translate }}">{{'formFields.pending'
                                                    |
                                                    translate }}</mat-option>
                                                <mat-option
                                                    value="{{'formFields.inactive' | translate }}">{{'formFields.inactive' |
                                                    translate }}</mat-option>
                                            </mat-select>
                                            <!-- <mat-error>
                                                    <span *ngIf="!siteForm.get('Status')?.valid">
                                                        Please select Status.</span>
                                                </mat-error>
                                                <mat-error>
                                                    <span *ngIf="!siteForm.get('Status')?.errors?.serverError">
                                                        {{siteForm.get('Status')?.errors?.serverError}}</span>                                            
                                                </mat-error> -->
                                        </mat-form-field>
                                    </div>
        
                                    <div class="form-group col-md-6 ucg-input">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>{{'formFields.zipCode' | translate}}</mat-label>
                                            <input matInput autocomplete="off"
                                                placeholder="{{'formFields.zipCode' | translate}}" tabindex="10" type="text"
                                                (keypress)="onKeyPress($event)" minlength="5" maxlength="6"
                                                formControlName="zipCode" required>
                                            <mat-error>
                                                <span
                                                    *ngIf="(!tenantForm.get('zipCode')?.errors?.serverError && !tenantForm.get('zipCode')?.valid) && !zip && !tenantForm.get('zipCode')?.errors?.pattern">
                                                    {{'requiredFields.zipCode' | translate }}</span>
                                                <span
                                                    *ngIf="!tenantForm.get('zipCode')?.errors?.serverError && (zip || (tenantForm.get('zipCode')?.valid && !zip))">
                                                    {{'requiredFields.zipCodeLength' | translate }}</span>
                                                <span *ngIf="tenantForm.get('zipCode')?.errors?.pattern | translate">
                                                    {{'requiredFields.zipCodePattern' | translate}}</span>
                                            </mat-error>
                                            <mat-error>
                                                <span *ngIf="tenantForm.get('zipCode')?.errors?.serverError | translate">
                                                    {{tenantForm.get('zipCode')?.errors?.serverError | translate}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <!-- <div class="form-group col-md-6"> -->
        
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="btn-group d-grid gap-2 d-md-flex justify-content-md-end">
                            <button *ngIf="canUpdateTenant" [ngClass]="{'img-disable': parentTenantRequest}" mat-raised-button
                                color="primary" class="btn-primary mx-3" (click)="updateTenant();">
                                {{'button.update' | translate }}
                            </button>
                            <button mat-raised-button color="secondary" class="btn-secondary" (click)="cancel();">
                                {{'button.cancel' | translate }}
                            </button>
                            <button *ngIf="signUpInviteFlag" mat-raised-button color="primary" class="btn-primary mx-3"
                                (click)="signUpInvite();">
                                {{'button.signUpInvite' | translate }}
                            </button>
                        </div>
                    </form>
            </div>
        
        </div>
    </div>


    <div class="card mat-elevation-z mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 lg-6">
            <h2 class="h2">{{'allTypeContent.siteList' | translate }}</h2>
          </div>
          <!-- <div *ngIf="createTenant" class="col-md-6 lg-6 text-end">
            <span class="creation-button">
              <button mat-raised-button color="primary" class="btn-primary"
                (click)="navigateTenant();">{{'button.newTenant' | translate}}</button>
            </span>
          </div> -->
          <div class="col-md-6 lg-6 text-end">
            <div class="head-right-outer">
                <a class="btn-border mat-elevation-z icon-right" mat-raised-button data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Filter<span class="material-symbols-outlined">keyboard_arrow_down</span></a>
                <a *ngIf="canCreateSite" class="btn-border mat-elevation-z" (click)="navigateSite();" mat-raised-button href="javascript:;"><span class="material-symbols-outlined" [ngClass]="{'img-disable': parentTenantRequest}">add</span> {{'button.newSite' | translate }} </a>
                <a class="btn-back mat-elevation-z" mat-raised-button href="javascript:;"><span class="material-symbols-outlined">keyboard_backspace</span> Back</a>
            </div>
          </div>
        </div>
        <div class="filter-outer">
            <div class="collapse" id="multiCollapseExample1">
            <div class="row">
                <div class="col-md-3 lg-3">
                  
                  <!-- <div class="input-group input-group-outline my-3">
                    <label class="form-label">Filter by Name</label>
                    <input type="text" [formControl]="nameControl" class="form-control">
                  </div> -->

                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Filter by Name</mat-label>
                    <input type="text" matInput [formControl]="siteNameControl" [matAutocomplete]="autoName"
                        (change)="filterChange(filterSelectObj[0], $event)">
                    <mat-autocomplete autoActiveFirstOption #autoName="matAutocomplete"
                        (optionSelected)="filterChange(filterSelectObj[0], $event)">
                        <mat-option *ngFor="let option of filteredBySiteName | async" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                </div>
                <div class="col-md-3 lg-3">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Filter by Location</mat-label>
                        <input type="text" matInput [formControl]="locationControl" [matAutocomplete]="autoLocation"
                            (change)="filterChange(filterSelectObj[1], $event)">
                        <mat-autocomplete autoActiveFirstOption #autoLocation="matAutocomplete"
                            (optionSelected)="filterChange(filterSelectObj[1], $event)">
                            <mat-option *ngFor="let option of filteredByLocation | async" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-md-3 lg-3">
                    <div class="form-check form-switch">
                        <mat-slide-toggle [color]="'primary'" (change)="toggleDeletedRecords()">Show
                            Deleted Records</mat-slide-toggle>
                        <!-- <input class="form-check-input" (change)="toggleDeletedRecords()" type="checkbox" id="flexSwitchCheckDefault" checked="">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Show Deleted Records</label> -->
                    </div>
                </div>
                <div class="col-md-3 lg-3">
                    <a href="javascript:;" mat-raised-button class="reset-btn mat-elevation-z" (click)="resetFilters()"><span class="material-symbols-outlined">restart_alt</span> Reset</a>
                </div>
            </div>
            </div>
        </div>
        <div class="body-content-outer">
            <div class="row">
                <div class="col-md-12 lg-12">
  
                  <div class="ucg-table">
                    <mat-table #table [dataSource]="dataSource" matSort matSortActive="registrationDate"
                    matSortDirection="desc">

                    <ng-container matColumnDef="registrationDate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.registrationDate |
                            date:"MM-dd-yyyy h:mm a"}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="siteId">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'table.header.siteId' | translate }}.
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.tenantId}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'table.header.name' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="textMaxLength"
                            [ngClass]="{'disable-link': deletedRecords}" (click)="siteEdit(element);"
                            style="cursor:pointer;"> {{element.name}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="location">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'table.header.location' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.location}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status" style="cursor: default;cursor:pointer;">
                        <mat-header-cell *matHeaderCellDef class="spc-action"
                            [ngClass]="{'disable-link hide': deletedRecords}"> Action </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="spc-even"
                            [ngClass]="{'disable-link hide': deletedRecords}">


                            <div class="table-action">
                                <a *ngIf="!element.isRequestRaised && canDeleteSite" matTooltip="Site Details" mat-mini-fab color="primary" href="javascript:;" (click)="siteEdit(element)" class="table-action-btn">
                                  <span class="material-symbols-outlined">info</span>
                                </a>
                                <a href="javascript:;" mat-mini-fab color="warn" matTooltip="Delete Site" *ngIf="!element.isRequestRaised && canDeleteSite" (click)="deleteSite(element)" class="table-action-btn">
                                  <span class="material-symbols-outlined">delete</span>
                                </a>


                                <a href="javascript:;" mat-mini-fab color="success" matTooltip="Undo Delete Site" *ngIf="element.isRequestRaised && canDeleteSite && !deletedRecords"
                                (click)="unDeleteSite(element)" class="table-action-btn">
                                  <span class="material-symbols-outlined">undo</span>
                                </a>
                            </div>

                        
                            
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            
                <div *ngIf="process" class="text-center norecord table-loader">
                               
                    <div class="loader-inner">
                      <mat-spinner [diameter]="40"></mat-spinner>
                      <small>Loading</small>
                    </div>
                  </div>
            
                    <div *ngIf="!process && (dataSource.data.length == 0 || dataSource.filteredData.length == 0)" class="norecord">
                      {{'table.noRecordFound' | translate}}</div>
            
                    <mat-paginator #paginator [length]="totalCount" [pageIndex]="pageNumber" [pageSize]="pageSize"
                      [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" (page)="pageChanged($event)">
                    </mat-paginator>
                  </div>
                    
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- OLD Design -->
  


