


<!-- New Design -->

<div class="mt-3">
  <div class="card mat-elevation-z mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 lg-6">
          <h2 class="h2">{{'allTypeContent.tenantList' | translate}}</h2>
        </div>
        <!-- <div *ngIf="createTenant" class="col-md-6 lg-6 text-end">
          <span class="creation-button">
            <button mat-raised-button color="primary" class="btn-primary"
              (click)="navigateTenant();">{{'button.newTenant' | translate}}</button>
          </span>
        </div> -->
        <div class="col-md-6 lg-6 text-end">
          <div class="head-right-outer">
            
              <a class="btn-border mat-elevation-z icon-right"  data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1" mat-raised-button >Filter<span class="material-symbols-outlined">keyboard_arrow_down</span>
              </a>
              <a class="btn-border mat-elevation-z" (click)="navigateTenant();" mat-raised-button href="javascript:;"><span class="material-symbols-outlined">add</span> {{'button.newTenant' | translate}} </a>
              <a class="btn-back mat-elevation-z" mat-raised-button href="javascript:;"><span class="material-symbols-outlined">keyboard_backspace</span> Back</a>
          </div>
        </div>
      </div>
      <div class="filter-outer"  >
        <div class="collapse" id="multiCollapseExample1">
          <div class="row">
              <div class="col-md-3 lg-3">
                <mat-form-field class="w-100 ucg-input" appearance="outline">
                  <mat-label>Filter by Name</mat-label>
                  <input matInput placeholder="Filter by Name" maxlength="150" min="3" max="150"
                  [formControl]="nameControl">
                </mat-form-field>
                <!-- <div class="input-group input-group-outline my-3">
                  <label class="form-label">Filter by Name</label>
                  <input type="text" [formControl]="nameControl" class="form-control">
                </div> -->
              </div>
              <div class="col-md-3 lg-3">
                <mat-form-field class="w-100 ucg-input" appearance="outline">
                  <mat-label>Filter by Location</mat-label>
                  <input matInput placeholder="Filter by Location" maxlength="150" min="3" max="150"
                  [formControl]="locationControl">
                </mat-form-field>
              </div>
              <div class="col-md-3 lg-3">
                  <div class="form-check form-switch">
                    <mat-slide-toggle [color]="'primary'" (change)="toggleDeletedRecords()">Show
                      Deleted Records</mat-slide-toggle>
                      <!-- <input class="form-check-input" (change)="toggleDeletedRecords()" type="checkbox" id="flexSwitchCheckDefault" checked="">
                      <label class="form-check-label" for="flexSwitchCheckDefault">Show Deleted Records</label> -->
                  </div>
              </div>
              <div class="col-md-3 lg-3">
                  <a href="javascript:;" mat-raised-button class="reset-btn mat-elevation-z" (click)="resetFilters()"><span class="material-symbols-outlined">restart_alt</span> Reset</a>
              </div>
          </div>
        </div>
      </div>
      <div class="body-content-outer">
          <div class="row">
              <div class="col-md-12 lg-12">

                <div class="ucg-table">
                  <mat-table #table [dataSource]="dataSource" matSort matSortActive="registrationDate" matSortDirection="desc">
          
                    <ng-container matColumnDef="registrationDate">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.registrationDate |
                        date:"MM-dd-yyyy h:mm a"}} </mat-cell>
                    </ng-container>
          
                    <ng-container matColumnDef="tenantId">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> {{'table.header.tenantId' | translate}}
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.tenantId}} </mat-cell>
                    </ng-container>
          
                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-200"> {{'table.header.name' | translate}} </mat-header-cell>
                      <mat-cell *matCellDef="let element" class="wd-200" [ngClass]="{'disable-link': deletedRecords}" (click)="edit(element);"
                        style="cursor:pointer;"> {{element.name}}
                      </mat-cell>
                    </ng-container>
          
                    <ng-container matColumnDef="location">
                      <mat-header-cell *matHeaderCellDef mat-sort-header > {{'table.header.location' | translate}}
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element" >{{element.location}} </mat-cell>
                    </ng-container>
          
                    <ng-container matColumnDef="sitescount" >
                      <mat-header-cell *matHeaderCellDef mat-sort-header class="alignCenter Col-number wd-100"> {{'table.header.sites' | translate}}
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element" class="alignCenter Col-number wd-100"> 
                        <div>
                          <b>{{element.sitescount}}</b><span *ngIf="deletedRecords && element.siteList.length"> - {{ element.siteList.join(', ') }}</span>
                        </div>
                      </mat-cell>
                    </ng-container>
          
                    <ng-container matColumnDef="chargepointscount">
                      <mat-header-cell *matHeaderCellDef mat-sort-header class="alignCenter Col-number wd-100"> {{'table.header.chargePoints' |
                        translate}} </mat-header-cell>
                      <mat-cell *matCellDef="let element" class="alignCenter Col-number wd-100"> 
                        <div>
                          <b>{{element.chargepointscount}}</b><span *ngIf="deletedRecords && element.chargePointList.length"> - {{ element.chargePointList.join(', ') }}</span>
                        </div>
                      </mat-cell>
                    </ng-container>
          
                    <ng-container matColumnDef="status" style="cursor: default;cursor:pointer;">
                      <mat-header-cell *matHeaderCellDef class="spc-action wd-200 action-column" [ngClass]="{'disable-link hide': deletedRecords}">
                        Action </mat-header-cell>
                      <mat-cell *matCellDef="let element" class="wd-200 action-column" [ngClass]="{'disable-link hide': deletedRecords}">
                        <div class="table-action">
                          <a matTooltip="Organization Details" mat-mini-fab color="primary" href="javascript:;" (click)="edit(element);" class="table-action-btn">
                            <span class="material-symbols-outlined">info</span>
                          </a>
                          <a href="javascript:;" mat-mini-fab color="warn" matTooltip="Delete Organization" *ngIf="!element.isRequestRaised && canDeleteTenant" (click)="deleteTenant(element)" class="table-action-btn">
                            <span class="material-symbols-outlined">delete</span>
                          </a>
                          <a href="javascript:;" mat-mini-fab color="success" matTooltip="Undo Delete Organization" *ngIf="element.isRequestRaised && !deletedRecords"
                          (click)="unDeleteTenant(element)" class="table-action-btn">
                            <span class="material-symbols-outlined">undo</span>
                          </a>
                        </div>
                      </mat-cell>
                    </ng-container>
          
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
          
                  <div *ngIf="process" class="text-center norecord table-loader">
                    
                    <div class="loader-inner">
                      <mat-spinner [diameter]="40"></mat-spinner>
                      <small>Loading</small>
                    </div>
                  </div>
          
                  <div *ngIf="!process && (dataSource.data.length == 0 || dataSource.filteredData.length == 0)" class="norecord">
                    {{'table.noRecordFound' | translate}}</div>
          
                  <mat-paginator #paginator [length]="totalCount" [pageIndex]="pageNumber" [pageSize]="pageSize"
                    [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" (page)="pageChanged($event)">
                  </mat-paginator>
                </div>
                  
              </div>
          </div>
      </div>
    </div>
  </div>
</div>

<!-- OLD Design -->
