<!-- New Design -->

<div class="mt-3">
    <div class="card mat-elevation-z mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 lg-6">
            
            <h2 class="h2" *ngIf="!isMasterAdmin" class="margin-top-16">{{'sidebar.raiseQuery' | translate }}</h2>
            <h2 class="h2" *ngIf="isMasterAdmin" class="margin-top-16">{{'sidebar.answerQuery' | translate }}</h2>
          </div>
          <!-- <div *ngIf="createTenant" class="col-md-6 lg-6 text-end">
            <span class="creation-button">
              <button mat-raised-button color="primary" class="btn-primary"
                (click)="navigateTenant();">{{'button.newTenant' | translate}}</button>
            </span>
          </div> -->
          <div class="col-md-6 lg-6 text-end">
            <div class="head-right-outer">
                <a class="btn-border mat-elevation-z icon-right" mat-raised-button data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Filter<span class="material-symbols-outlined">keyboard_arrow_down</span></a>
                <a *ngIf="!isMasterAdmin" class="btn-border mat-elevation-z" (click)="sendQueryDetails({}, 'new')"; mat-raised-button href="javascript:;"><span class="material-symbols-outlined">add</span> New Query </a>
                <a class="btn-back mat-elevation-z" mat-raised-button href="javascript:;"><span class="material-symbols-outlined">keyboard_backspace</span> Back</a>
            </div>
          </div>
        </div>
        <div class="filter-outer">
          <div class="collapse" id="multiCollapseExample1">
            <div class="row" *ngIf="isMasterAdmin">
                <div class="col-md-3">
                    <span class="title-section">
                        <h4>Queries</h4>
                    </span>
                </div>
                <div class="col-md-3 lg-3">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Filter by User</mat-label>
                        <input type="text" matInput [formControl]="userControl">
                    </mat-form-field>
                  <!-- <div class="input-group input-group-outline my-3">
                    <label class="form-label">Filter by Name</label>
                    <input type="text" [formControl]="nameControl" class="form-control">
                  </div> -->
                </div>
                <div class="col-md-3 lg-3">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Filter by Status</mat-label>
                        <input type="text" matInput [formControl]="userStatusControl">
                    </mat-form-field>
                </div>
                
                <div class="col-md-3 lg-3">
                    <a href="javascript:;" mat-raised-button class="reset-btn mat-elevation-z" (click)="resetFilters()"><span class="material-symbols-outlined">restart_alt</span> Reset</a>
                </div>
            </div>
            </div>
        </div>
        <div class="body-content-outer">
            <div class="row">
                <div class="col-md-12 lg-12">
  
                  <div class="ucg-table">
                    <mat-table #table [dataSource]="dataSource" matSort matSortDirection="asc">
                        <!-- matSortActive="userName" -->
                        <ng-container matColumnDef="userName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> User </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.userName }} </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="tenantName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Organization </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.tenantName }} </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="siteName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Site </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.siteName }} </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="chargePointName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> ChargePoint </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.chargePointName }} </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="createdon">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Created On </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.createdon }} </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="description">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                            <mat-cell *matCellDef="let element"> <span class="truncate-text">{{ element.description }}</span> </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.status }} </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="action" style="cursor: default;cursor:pointer;">
                            <mat-header-cell *matHeaderCellDef class="spc-even"> Action </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="spc-even">
                              <a  href="javascript:;" mat-mini-fab color="success"
                              matTooltip="Reply Query" (click)="sendQueryDetails(element, 'edit')"
                              class="table-action-btn" *ngIf="isMasterAdmin || element?.reply?.length">
                                  <span class="material-symbols-outlined">undo</span>
                              </a>

                              <a  href="javascript:;" mat-mini-fab color="success"
                              matTooltip="Edit Query Details" (click)="sendQueryDetails(element, 'edit')"
                              class="table-action-btn" *ngIf="!isMasterAdmin && !element?.reply?.length">
                                  <span class="material-symbols-outlined">undo</span>
                              </a>
                              <a *ngIf="!isMasterAdmin" href="javascript:;" mat-mini-fab color="warn" matTooltip="Delete Chargepoint" (click)="deleteQuery(element.id)" class="table-action-btn">
                                <span class="material-symbols-outlined">delete</span>
                                </a>
                                
                            </mat-cell>
                        </ng-container>
    
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
            
                    <div *ngIf="process" class="text-center norecord table-loader">
                               
                      <div class="loader-inner">
                        <mat-spinner [diameter]="40"></mat-spinner>
                        <small>Loading</small>
                      </div>
                    </div>
            
                    <div *ngIf="!process && (dataSource.data.length == 0 || dataSource.filteredData.length == 0)" class="norecord">
                      {{'table.noRecordFound' | translate}}</div>
            
                    <mat-paginator #paginator [length]="totalCount" [pageIndex]="pageNumber" [pageSize]="pageSize"
                      [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" (page)="pageChanged($event)">
                    </mat-paginator>
                  </div>
                    
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- OLD Design -->
  
